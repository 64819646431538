import React, { Component } from 'react';

import Link from '../Link';

import 'css/components/widget/_WidgetHomeLink.scss';

export default class WidgetHomeLink extends Component {
  render() {
    return (
      <Link className="widgetHomeLink" to="/">
        <span className="arrow">&larr;</span>
        <span className="text">Back to all&nbsp;posts</span>
      </Link>
    );
  }
}
