import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { invalidatePostQueries, loadMore } from 'common/actions/postQueries';
import { invalidateSuggestions } from 'common/actions/postSuggestions';
import { invalidateUserQueries } from 'common/actions/userQueries';
import AJAX from 'common/AJAX';
import BoardNotificationsContainer from 'common/containers/BoardNotificationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { GetExperimentVariationContext } from 'common/containers/ExperimentContainer';
import { GetPostLinkContext } from 'common/containers/PostLinkContainer';
import { TintColorContext } from 'common/containers/TintColorContainer';
import { ShowToastContext } from 'common/containers/ToastContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import translateString from 'common/i18n/translateString';
import Link from 'common/Link';
import Markdown from 'common/markdown/Markdown';
import Message from 'common/message/Message';
import NotificationsMenu from 'common/notifications/NotificationsMenu';
import CreatePostFormV2 from 'common/post/CreatePostFormV2';
import PostList from 'common/post/PostList';
import PostListItemV2 from 'common/post/PostListItemV2';
import { H1 } from 'common/ui/Text';
import delayer from 'common/util/delayer';
import getAuthRedirectURL from 'common/util/getAuthRedirectURL';
import parseAPIResponse from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';
import { ConfigContext } from 'common/widget/WidgetContext';
import WidgetSidebar from 'common/widget/WidgetSidebar';

import 'css/components/widget/_WidgetHome.scss';

class WidgetHome extends Component {
  static propTypes = {
    board: PropTypes.object,
    company: PropTypes.object,
    config: PropTypes.object,
    location: PropTypes.shape({
      state: PropTypes.object,
    }),
    notifications: PropTypes.object,
    postList: PropTypes.object,
    showToast: PropTypes.func,
    tintColor: PropTypes.string,
    viewer: PropTypes.object,
  };

  state = {
    isCreatingPost: false,
  };

  constructor(props, context) {
    super(props, context);

    this._formDelayer = new delayer(this.onCreatePostFormUpdate, 500);
  }

  componentDidMount() {
    this._unsubscribe = Message.subscribe(null, null, 'scrollBottomDetected', this.onLoadMore);

    const {
      location: {
        query: { postDeleted },
      },
      showToast,
    } = this.props;
    if (!postDeleted) {
      return;
    }

    setTimeout(() => {
      showToast('The post has been deleted');
    }, 500);
  }

  componentWillUnmount() {
    this._unsubscribe();
    this._formDelayer.cancel();
  }

  getDefaultCreatePostForm = () => {
    const { location, board } = this.props;
    const form = location.state?.createForm ?? {};
    const { selectedCategory } = location.query;

    if (!selectedCategory) {
      return form;
    }

    const category = board.categories.find((category) => category.urlName === selectedCategory);
    return {
      ...board,
      categoryID: category._id,
    };
  };

  onCreatePostFormUpdate = (form) => {
    const { location, router, board } = this.props;
    const category = form.categoryID
      ? board.categories.find((category) => category._id === form.categoryID)
      : null;

    router.replace({
      pathname: location.pathname,
      query: { ...location.query, selectedCategory: category?.urlName },
      state: Object.assign({ createForm: form }),
    });
  };

  resetForm = async () => {
    const { router, location } = this.props;
    router.replace({
      pathname: location.pathname,
      query: location.query,
      state: Object.assign({ createForm: null }),
    });

    this._formDelayer.cancel();
  };

  createPost = async (form) => {
    const { getPostLink, invalidatePostQueries, router } = this.props;

    this.setState({ isCreatingPost: true });

    const response = await AJAX.post('/api/posts/create', form);
    const { error, parsedResponse } = parseAPIResponse(response, {
      isSuccessful: (parsedResponse) => parsedResponse.post,
      errors: {
        'slow down':
          'You are trying to create posts too fast. Please wait a few minutes before trying again.',
        spam: `Our system identifies parts of this post as spam. Please, try with different content.`,
      },
    });

    this.setState({ isCreatingPost: false });

    if (error || !parsedResponse) {
      throw new Error(error.message);
    }

    this.resetForm();
    invalidatePostQueries();
    router.push(getPostLink(parsedResponse.post));
  };

  getRedirectURL() {
    const { company, config, location, viewer } = this.props;
    const { authRedirectEnabled, authRedirectURL } = company;
    if (!viewer.loggedOut || !authRedirectEnabled || !authRedirectURL) {
      return null;
    }

    return getAuthRedirectURL(company, location, config);
  }

  onLoadMore = () => {
    const { postList } = this.props;
    const { loadingMore, hasNextPage } = postList;
    if (loadingMore || !hasNextPage) {
      return;
    }

    const { paginate } = this.props;
    paginate(postList);
  };

  renderNoPostsMessage() {
    const { location } = this.props;
    if (location.query.search) {
      return "We couldn't find anything. Try a new search or create a new\u00a0post!";
    } else {
      const { state } = location;
      if (state && state.createForm && (state.createForm.title || state.createForm.details)) {
        return 'No suggested posts.';
      } else {
        return 'There are no posts.';
      }
    }
  }

  renderSuggestedPosts() {
    const { location, postList } = this.props;
    const {
      state: { createForm },
    } = location;
    const isPendingSearch = createForm.pendingSearch;
    const isLoadingTextSearch = (createForm.details || createForm.title) && postList.loading;
    if (isPendingSearch || isLoadingTextSearch) {
      return <div className="searching">Looking for similar posts...</div>;
    }

    return (
      <div className="suggestedPosts">
        <div className="heading">Suggested posts</div>
        <PostList
          ItemComponent={PostListItemV2}
          noPostsMessage={this.renderNoPostsMessage()}
          postList={postList}
          showComments={true}
          showMenu={false}
          showPrivateComments={false}
        />
      </div>
    );
  }

  renderCreatePostButton() {
    const { board, tintColor } = this.props;
    if (!board.settings.showCreateForm) {
      return null;
    }

    const linkStyle = {
      ...(tintColor && { backgroundColor: tintColor }),
    };
    const redirectURL = this.getRedirectURL();
    if (redirectURL) {
      return (
        <a className="createPost" href={redirectURL} target="_top">
          <div className="mobile icon-plus" style={linkStyle} />
        </a>
      );
    }

    return (
      <Link className="createPost" to="/create">
        <div className="mobile icon-plus" style={linkStyle} />
      </Link>
    );
  }

  renderAdditionalMenu() {
    const { board } = this.props;
    return (
      <div className="widgetButtons">
        <BoardNotificationsContainer>
          <NotificationsMenu board={board} showTooltip={false} tint={true} />
        </BoardNotificationsContainer>
        {this.renderCreatePostButton()}
      </div>
    );
  }

  renderPosts() {
    const { board, location, postList } = this.props;
    const { state } = location;
    const createForm = state && state.createForm;
    if (createForm && (createForm.pendingSearch || createForm.title || createForm.details)) {
      return this.renderSuggestedPosts();
    }

    return (
      <PostList
        additionalMenu={this.renderAdditionalMenu()}
        board={board}
        ItemComponent={PostListItemV2}
        noPostsMessage={this.renderNoPostsMessage()}
        postList={postList}
        showComments={true}
        showPrivateComments={false}
      />
    );
  }

  render() {
    const { board } = this.props;
    return (
      <div className="widgetHome">
        <WidgetSidebar board={board} />
        <div className="mainContainer">
          <header className="boardHeader">
            <H1 variant="headingMd" fontWeight="semibold">
              {board.name}
            </H1>
            <div className="boardDescription">
              <Markdown contents={translateString(board.strings, 'description')} />
            </div>
          </header>
          {board.settings.showCreateForm && (
            <CreatePostFormV2
              board={board}
              loading={this.state.isCreatingPost}
              defaultVisibility="open"
              defaultForm={this.getDefaultCreatePostForm()}
              onCreate={this.createPost}
              onUpdate={this._formDelayer.callAfterDelay}
              onCancel={this.resetForm}
            />
          )}
          <div className="postListContainer">{this.renderPosts()}</div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    paginate: (postList) => {
      return Promise.all([dispatch(loadMore(postList))]);
    },
    invalidatePostQueries: () => {
      return Promise.all([
        dispatch(invalidatePostQueries()),
        dispatch(invalidateSuggestions()),
        dispatch(invalidateUserQueries()),
      ]);
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      config: ConfigContext,
      showToast: ShowToastContext,
      tintColor: TintColorContext,
      viewer: ViewerContext,
      getPostLink: GetPostLinkContext,
      getExperimentVariation: GetExperimentVariationContext,
    },
    {
      forwardRef: true,
    }
  )
)(WidgetHome);
