import React, { Component } from 'react';

import { iframeResizerContentWindow } from 'iframe-resizer'; // eslint-disable-line no-unused-vars
import { hot } from 'react-hot-loader/root';

import CannyRouter from 'common/core/CannyRouter';

import WidgetRoutes from './WidgetRoutes';

import 'css/widget.scss';
import './WidgetCSSOverrides';

window.__WIDGET__ = true;

class WidgetApp extends Component {
  render() {
    const { history } = this.props;
    return <CannyRouter history={history} routes={WidgetRoutes} />;
  }
}

export default hot(WidgetApp);
