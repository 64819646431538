import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { GetPostLinkContext } from 'common/containers/PostLinkContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { TintColorContext } from 'common/containers/TintColorContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import translateString from 'common/i18n/translateString';
import Button from 'common/inputs/Button';
import Markdown from 'common/markdown/Markdown';
import CreatePostForm from 'common/post/CreatePostForm';
import getAuthRedirectURL from 'common/util/getAuthRedirectURL';
import nbspLastSpace from 'common/util/nbspLastSpace';
import withContexts from 'common/util/withContexts';
import { ConfigContext } from 'common/widget/WidgetContext';

import 'css/components/widget/_WidgetBoardDetails.scss';

class WidgetBoardDetails extends Component {
  static propTypes = {
    board: PropTypes.shape({
      postCount: PropTypes.number,
      strings: PropTypes.shape({
        description: PropTypes.string,
      }),
    }),
    company: PropTypes.object,
    config: PropTypes.object,
    getPostLink: PropTypes.func,
    location: PropTypes.object,
    router: PropTypes.object,
    tintColor: PropTypes.string,
    viewer: PropTypes.object,
  };

  onPostCreated = (post) => {
    const { getPostLink, router } = this.props;
    router.push(getPostLink(post));
  };

  renderDescription() {
    const { board } = this.props;
    if (!board.strings.description) {
      return null;
    }

    return (
      <div className="description">
        <Markdown contents={nbspLastSpace(translateString(board.strings, 'description'))} />
      </div>
    );
  }

  renderCreatePostForm() {
    const { board } = this.props;
    if (!board.settings.showCreateForm) {
      return null;
    }

    return <CreatePostForm board={board} onPostCreated={this.onPostCreated} />;
  }

  render() {
    const { company, config, location, viewer } = this.props;
    const { authRedirectEnabled, authRedirectURL } = company;

    if (viewer.loggedOut && authRedirectEnabled && authRedirectURL) {
      const redirectURL = getAuthRedirectURL(company, location, config);
      return (
        <div className="widgetBoardDetails logInPrompt">
          <div className="heading">Log in to your {company.name} account to give&nbsp;feedback</div>
          <a href={redirectURL} className="logInLink" target="_top">
            <Button tint={true} value="Log In" />
          </a>
        </div>
      );
    }

    return null;
  }
}

export default withContexts({
  company: CompanyContext,
  config: ConfigContext,
  getPostLink: GetPostLinkContext,
  location: LocationContext,
  router: RouterContext,
  tintColor: TintColorContext,
  viewer: ViewerContext,
})(WidgetBoardDetails);
