import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { GetPostLinkContext } from 'common/containers/PostLinkContainer';
import withContexts from 'common/util/withContexts';

import EditPostForm from '../post/EditPostForm';

import 'css/components/widget/_WidgetEditPost.scss';

class SubdomainEditPost extends Component {
  static propTypes = {
    board: PropTypes.object,
    getPostLink: PropTypes.func,
    post: PropTypes.object,
    router: PropTypes.object,
  };

  onPostEdited = (post) => {
    const { getPostLink, router } = this.props;
    router.push(getPostLink(post));
  };

  render() {
    const { board, post } = this.props;
    return (
      <div className="widgetEditPost">
        <EditPostForm
          board={board}
          onPostEdited={this.onPostEdited}
          post={post}
          showCustomPostFields={post.viewerIsAuthor}
        />
      </div>
    );
  }
}

export default withContexts({ getPostLink: GetPostLinkContext })(SubdomainEditPost);
