import createEntry from 'common/core/createEntry';

import WidgetApp from './WidgetApp';
import WidgetReducers from './WidgetReducers';

const GA4MeasurementID = 'G-G3PCNK1C9Y';
const SentryDSN = '941e8dfb8fdc4db488bfe185a5ecec03';

createEntry({
  app: WidgetApp,
  ga4MeasurementID: GA4MeasurementID,
  reducers: WidgetReducers,
  sentryDSN: SentryDSN,
});
