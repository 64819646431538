import React, { Component } from 'react';

import PropTypes from 'prop-types';

import translateString from 'common/i18n/translateString';
import Link from 'common/Link';
import Markdown from 'common/markdown/Markdown';
import CreatePostForm from 'common/post/CreatePostForm';
import UppercaseHeader from 'common/UppercaseHeader';
import nbspLastSpace from 'common/util/nbspLastSpace';
import withContexts from 'common/util/withContexts';
import { IsIntercomContext } from 'common/widget/WidgetContext';

import 'css/components/widget/_WidgetCreate.scss';

// Mobile only
class WidgetCreate extends Component {
  static propTypes = {
    board: PropTypes.object,
    isIntercom: PropTypes.bool,
    postList: PropTypes.object,
    router: PropTypes.object,
  };

  onPostCreated = (post) => {
    this.props.router.push('/p/' + post.urlName);
  };

  renderHeader() {
    const { board, isIntercom } = this.props;
    if (isIntercom) {
      return null;
    }

    return (
      <div className="header">
        <div className="title">{translateString(board.strings, 'createHeading')}</div>
        <Link className="seeAll" to="/">
          <span className="text">See all posts</span>
          <span className="arrow">&rarr;</span>
        </Link>
      </div>
    );
  }

  renderPostListLink() {
    const { isIntercom } = this.props;
    if (!isIntercom) {
      return null;
    }

    return (
      <Link className="postListLink" to="/">
        <UppercaseHeader>OR see all posts</UppercaseHeader>
      </Link>
    );
  }

  render() {
    const { board, postList } = this.props;
    return (
      <div className="widgetCreate">
        {this.renderHeader()}
        <div className="boardDescription">
          <Markdown contents={nbspLastSpace(translateString(board.strings, 'description'))} />
        </div>
        <CreatePostForm
          autoFocus={true}
          board={board}
          onPostCreated={this.onPostCreated}
          showSuggestedPosts={true}
          suggestedPosts={postList}
        />
        {this.renderPostListLink()}
      </div>
    );
  }
}

export default withContexts({ isIntercom: IsIntercomContext })(WidgetCreate);
