import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { loadMorePostActivity } from 'common/actions/postsActivity';
import { GetPostLinkContext } from 'common/containers/PostLinkContainer';
import connect from 'common/core/connect';
import EditPostForm from 'common/post/EditPostForm';
import withContexts from 'common/util/withContexts';

import Message from '../message/Message';
import Post from '../post/Post';
import Spinner from '../Spinner';
import WidgetHomeLink from '../widget/WidgetHomeLink';
import WidgetSidebar from '../widget/WidgetSidebar';

import 'css/components/widget/_WidgetPost.scss';

class WidgetPost extends Component {
  static propTypes = {
    board: PropTypes.object,
    getPostLink: PropTypes.func,
    post: PropTypes.object,
    postActivity: PropTypes.shape({
      options: PropTypes.shape({
        limit: PropTypes.number,
        sort: PropTypes.string,
      }),
    }).isRequired,
    router: PropTypes.object,
  };

  state = {
    isEditing: false,
  };

  componentDidMount() {
    this._unsubscribe = Message.subscribe(null, null, 'scrollBottomDetected', this.onLoadMore);
  }

  componentWillUnmount() {
    this._unsubscribe();
  }

  onEdit = () => {
    this.setState({ isEditing: true });
  };

  onLoadMore = () => {
    this.props.loadMore(this.props.post);
  };

  onPostDeleted = () => {
    const { router } = this.props;
    router.push({
      pathname: '/',
      query: {
        postDeleted: true,
      },
    });
  };

  onPostEdited = (post) => {
    const { getPostLink, location, router } = this.props;
    this.setState({ isEditing: false });
    router.replace({
      pathname: getPostLink(post),
      query: location.query,
    });
  };

  renderContents() {
    const { board, post, postActivity } = this.props;
    if (this.state.isEditing) {
      return (
        <div className="editContainer card">
          <h1>Edit Post</h1>
          <EditPostForm
            board={board}
            onPostEdited={this.onPostEdited}
            post={post}
            showCustomPostFields={post.viewerIsAuthor}
          />
        </div>
      );
    }

    return (
      <Post
        additionalMenu={<WidgetHomeLink />}
        board={board}
        onEdit={this.onEdit}
        onPostDeleted={this.onPostDeleted}
        post={post}
        postActivity={postActivity}
        showCustomPostFields={post.viewerIsAuthor}
        viewType="public"
      />
    );
  }

  render() {
    const { board, post } = this.props;
    if (post.error) {
      return (
        <div className="widgetPost">
          <div className="notFound">Something went wrong, please try again later.</div>
        </div>
      );
    } else if (post.loading) {
      return (
        <div className="widgetPost">
          <div className="loading">
            <Spinner />
          </div>
        </div>
      );
    } else if (post.notFound) {
      return (
        <div className="widgetPost">
          <div className="notFound">There is no such post.</div>
        </div>
      );
    }

    return (
      <div className="widgetPost">
        <WidgetSidebar board={board} post={post} />
        <div className="mainContainer">{this.renderContents()}</div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    loadMore: (post) => {
      return dispatch(loadMorePostActivity(post));
    },
  })),
  withContexts(
    { getPostLink: GetPostLinkContext },
    {
      forwardRef: true,
    }
  )
)(WidgetPost);
