import { Component } from 'react';

import { loadNotifications } from '../actions/notifications';
import asyncConnect from '../core/asyncConnect';
import cloneElementWithProps from '../core/cloneElementWithProps';

const asyncFetch = {
  promise: ({ store: { dispatch, getState }, params }) => {
    const state = getState();
    const board = state.widget ? state.widget.board : state.boards.items[params.boardURLName];
    if (!board || !board._id) {
      return;
    }
    return dispatch(loadNotifications(board._id));
  },
};

class BoardNotificationsContainer extends Component {
  static asyncConnect = asyncFetch;

  render() {
    const { children, notifications } = this.props;
    return cloneElementWithProps(children, {
      ...this.props,
      notifications,
    });
  }
}

export default asyncConnect([asyncFetch], (state) => ({ notifications: state.notifications }))(
  BoardNotificationsContainer
);
