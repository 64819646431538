import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as reduxAsyncConnect } from 'redux-connect';

import userReactions from 'common/reducers/userReactions';

import cookies from '../reducers/cookies';
import mentionSuggestions from '../reducers/mentionSuggestions';
import notifications from '../reducers/notifications';
import postQueries from '../reducers/postQueries';
import posts from '../reducers/posts';
import postsActivity from '../reducers/postsActivity';
import widget from '../reducers/widget';

export default combineReducers({
  cookies,
  mentionSuggestions,
  notifications,
  posts,
  postsActivity,
  postQueries,
  reduxAsyncConnect,
  routing: routerReducer,
  userReactions,
  widget,
});
