import React, { Component } from 'react';

import CannyAttribution from '../CannyAttribution';

import 'css/components/widget/_WidgetFooter.scss';

export default class WidgetFooter extends Component {
  render() {
    return (
      <div className="widgetFooter">
        <div className="widgetFooterContents">
          <CannyAttribution externalLink={true} source="feedback_widget" />
        </div>
      </div>
    );
  }
}
