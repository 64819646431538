import { REQUEST_WIDGET_DATA, WIDGET_DATA_ERROR, WIDGET_DATA_LOADED } from '../actions/widget';

export default function widget(state = null, action) {
  switch (action.type) {
    case REQUEST_WIDGET_DATA:
      return Object.assign({}, state, {
        company: { loading: true },
        error: null,
        lastUpdated: action.timestamp,
        loading: true,
        viewer: { loading: true },
      });

    case WIDGET_DATA_LOADED:
      return Object.assign({}, state, {
        board: action.result.board,
        company: action.result.company,
        error: null,
        lastUpdated: action.timestamp,
        loading: false,
        viewer: action.result.viewer || { loggedOut: true },
      });

    case WIDGET_DATA_ERROR: {
      const error = action.error;
      return Object.assign({}, state, {
        company: { error },
        error: action.error,
        lastUpdated: action.timestamp,
        loading: false,
        viewer: { error },
      });
    }

    default:
      return state;
  }
}
