import React from 'react';

import { IndexRoute, Route } from 'react-router';

import Widget404 from './Widget404';
import WidgetContainer from './WidgetContainer';
import WidgetCreate from './WidgetCreate';
import WidgetEditPost from './WidgetEditPost';
import WidgetHome from './WidgetHome';
import WidgetNotifications from './WidgetNotifications';
import WidgetPost from './WidgetPost';
import BoardNotificationsContainer from '../containers/BoardNotificationsContainer';
import PostActivityContainer from '../containers/PostActivityContainer';
import PostContainer from '../containers/PostContainer';
import PostListContainer from '../containers/PostListContainer';

export default (
  <Route path="/" component={WidgetContainer}>
    {/* home */}
    <Route component={PostListContainer}>
      <IndexRoute component={WidgetHome} />
    </Route>

    {/* post */}
    <Route path="/p/:postURLName" component={PostContainer}>
      <Route component={PostActivityContainer}>
        <IndexRoute component={WidgetPost} />
      </Route>
      <Route path="edit" component={WidgetEditPost} />
    </Route>

    {/* create */}
    <Route component={PostListContainer}>
      <Route path="/create" component={WidgetCreate} />
    </Route>

    <Route path="/notifications" component={BoardNotificationsContainer}>
      <IndexRoute component={WidgetNotifications} />
    </Route>

    {/* 404 */}
    <Route path="*" component={Widget404} />
  </Route>
);
