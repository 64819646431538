import React, { Component, cloneElement } from 'react';

import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import { setCookies } from 'common/actions/cookies';
import { loadWidgetData } from 'common/actions/widget';
import AJAX from 'common/AJAX';
import AuthContainer from 'common/containers/AuthContainer';
import BoardNotificationsContainer from 'common/containers/BoardNotificationsContainer';
import CompanyContainer from 'common/containers/CompanyContainer';
import ExperimentContainer from 'common/containers/ExperimentContainer';
import IsWidgetContainer from 'common/containers/IsWidgetContainer';
import ModalContainer from 'common/containers/ModalContainer';
import PostLinkContainer from 'common/containers/PostLinkContainer';
import RouterContainer from 'common/containers/RouterContainer';
import ThemeContainer from 'common/containers/ThemeContainer';
import TintColorContainer from 'common/containers/TintColorContainer';
import ToastContainer from 'common/containers/ToastContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import asyncConnect from 'common/core/asyncConnect';
import Helmet from 'common/helmets/Helmet';
import Message from 'common/message/Message';
import {
  ConfigContext,
  IsIntercomContext,
  LinkPrefixContext,
  OnAuthTokenContext,
} from 'common/widget/WidgetContext';
import WidgetError from 'common/widget/WidgetError';
import WidgetFooter from 'common/widget/WidgetFooter';
import WidgetLoading from 'common/widget/WidgetLoading';

import 'css/components/widget/_WidgetContainer.scss';

const isLoading = (props, state) => {
  const { config } = state;
  const {
    location: { query },
    widgetData,
  } = props;
  return !config || !widgetData || widgetData.loading || query.boardToken;
};

class WidgetContainer extends Component {
  static propTypes = {
    cookies: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    setCookies: PropTypes.func.isRequired,
    widgetData: PropTypes.object.isRequired,
  };

  state = {
    config: null,
    isIntercom: this.props.location.query.display === 'intercom',
    isLoaded: false,
    parentWindowViewport: null,
  };

  componentDidMount() {
    this._unsubscribes = [
      Message.subscribe(null, null, 'config', this.onConfigMessage),
      Message.subscribe(null, null, 'scroll', (parentWindowViewport) => {
        this.setState({ parentWindowViewport });
      }),
    ];

    Message.postMessage(window.parent, '*', 'ready', {});

    const { location, router, widgetData } = this.props;

    const { query } = location;
    if (query.boardToken) {
      AJAX.injectCookies({
        boardToken: query.boardToken,
        ...(query.ssoToken && {
          ssoToken: query.ssoToken,
        }),
      });
    }

    if (widgetData?.viewer?.csrfToken) {
      AJAX.injectCookies({
        csrfToken: widgetData.viewer.csrfToken,
      });
    }

    const newQuery = Object.assign({}, query);
    delete newQuery.boardToken;
    delete newQuery.display;
    delete newQuery.ssoToken;

    router.replace({
      pathname: location.pathname,
      query: newQuery,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isLoaded) {
      return;
    }

    const wasLoading = isLoading(prevProps, prevState);
    const isLoaded = isLoading(this.props, this.state);
    if (wasLoading && !isLoaded) {
      this.setState({ isLoaded: true });
      Message.postMessage(window.parent, '*', 'loaded', {});
    }
  }

  componentWillUnmount() {
    this._unsubscribes.forEach((unsubscribe) => {
      unsubscribe();
    });
    this._unlisten();
  }

  getLinkPrefix = () => {
    const { config } = this.state;
    if (!config) {
      return null;
    }

    const { basePath, origin } = config;
    if (!basePath || !origin) {
      return null;
    }

    return origin + basePath;
  };

  getPostLink = (post) => {
    return '/p/' + post.urlName;
  };

  onAuthToken = async (ssoToken) => {
    // update cookies so that mutations work (post/comment/vote creation)
    const updatedCookies = {
      ...this.props.cookies,
      ssoToken,
    };
    await this.props.setCookies(updatedCookies);
    AJAX.injectCookies(updatedCookies);

    // Wait for the SDK to set the token in local storage
    return new Promise((resolve, reject) => {
      // subscribe to messages from the SDK that the token got set
      const tokenSetUnsubscribe = Message.subscribe(null, null, 'token-set', () => {
        clearTimeout(timer);
        tokenSetUnsubscribe();
        resolve();
      });

      // if we don't hear back in 3 seconds, resolve anyway (should be basically 1ms)
      const timer = setTimeout(() => {
        tokenSetUnsubscribe();
        resolve();
      }, 3000);

      // tell the SDK to set the token
      Message.postMessage(window.parent, '*', 'set-auth-token', ssoToken);
    });
  };

  isSafeUrl = (url) => {
    // A basic check to disallow 'javascript:' URLs
    const protocol = new URL(url).protocol;
    return protocol === 'https:';
  };

  onConfigMessage = (config) => {
    // only allow config to be set once
    if (this.state.config) {
      return;
    }

    if (config.origin && !this.isSafeUrl(config.origin)) {
      delete config.origin;
    }

    if (config.initialPath) {
      const { router } = this.props;
      const initialQuery = Object.assign({}, config.initialQuery);
      delete initialQuery.boardToken;
      delete initialQuery.display;
      delete initialQuery.ssoToken;
      router.replace({
        pathname: config.initialPath,
        ...(initialQuery && { query: initialQuery }),
      });
    }

    this.setState({
      config: config,
    });

    this._unlisten = browserHistory.listen(this.onRouteChange);
  };

  onRouteChange = (location) => {
    if (!this.state.config || !window.parent) {
      return;
    }

    const path = location.pathname + location.search;
    Message.postMessage(window.parent, '*', 'path', path);
  };

  renderContent = () => {
    const { widgetData } = this.props;
    const { company } = widgetData;
    if (isLoading(this.props, this.state)) {
      return <WidgetLoading />;
    } else if (widgetData.error) {
      return <WidgetError />;
    }

    const { tintColor } = company;
    return (
      <ConfigContext.Provider value={this.state.config}>
        <IsIntercomContext.Provider value={this.state.isIntercom}>
          <LinkPrefixContext.Provider value={this.getLinkPrefix()}>
            <OnAuthTokenContext.Provider value={this.onAuthToken}>
              <RouterContainer
                location={this.props.location}
                params={this.props.params}
                router={this.props.router}>
                <CompanyContainer company={company}>
                  <ViewerContext.Provider value={this.props.widgetData.viewer || { loading: true }}>
                    <IsWidgetContainer isWidget={true}>
                      <PostLinkContainer getPostLink={this.getPostLink}>
                        <TintColorContainer tintColor={tintColor}>
                          <ExperimentContainer>
                            <AuthContainer>
                              <ModalContainer
                                parentWindowViewport={this.state.parentWindowViewport}>
                                <ToastContainer>
                                  <div className="widgetContainer">
                                    <Helmet title="Canny" />
                                    {cloneElement(this.props.children, {
                                      board: widgetData.board,
                                    })}
                                    <WidgetFooter />
                                  </div>
                                  <div id="fb-root" />
                                </ToastContainer>
                              </ModalContainer>
                            </AuthContainer>
                          </ExperimentContainer>
                        </TintColorContainer>
                      </PostLinkContainer>
                    </IsWidgetContainer>
                  </ViewerContext.Provider>
                </CompanyContainer>
              </RouterContainer>
            </OnAuthTokenContext.Provider>
          </LinkPrefixContext.Provider>
        </IsIntercomContext.Provider>
      </ConfigContext.Provider>
    );
  };

  render() {
    const { config } = this.state;

    return (
      <div>
        <ThemeContainer configTheme={config?.theme}>{this.renderContent()}</ThemeContainer>
      </div>
    );
  }
}

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch }, location: { query } }) => {
        const promises = [dispatch(loadWidgetData(query.boardToken, query.ssoToken))];

        if (query.boardToken) {
          promises.push(
            dispatch(
              setCookies({
                boardToken: query.boardToken,
                ...(query.ssoToken && {
                  ssoToken: query.ssoToken,
                }),
              })
            )
          );
        }

        return Promise.all(promises);
      },
    },
    BoardNotificationsContainer.asyncConnect,
  ],
  (state) => ({ cookies: state.cookies, widgetData: state.widget }),
  (dispatch) => ({
    setCookies: (cookies) => dispatch(setCookies(cookies)),
  })
)(WidgetContainer);
