import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { TintColorContext } from 'common/containers/TintColorContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import withContexts from 'common/util/withContexts';

import { loadMoreNotifications } from '../actions/notifications';
import AJAX from '../AJAX';
import connect from '../core/connect';
import Notification from '../notifications/Notification';
import Spinner from '../Spinner';
import Tappable from '../Tappable';
import WidgetHomeLink from '../widget/WidgetHomeLink';

import 'css/components/widget/_WidgetNotifications.scss';

class WidgetNotifications extends Component {
  static propTypes = {
    notifications: PropTypes.shape({
      hasNextPage: PropTypes.bool,
      items: PropTypes.array,
    }),
    tintColor: PropTypes.string,
    viewer: PropTypes.shape({
      _id: PropTypes.string,
    }),
  };

  componentDidMount() {
    const { viewer } = this.props;
    if (!viewer || !viewer._id) {
      return;
    }

    AJAX.post('/api/notifications/markAllSeen', {}, () => {});
  }

  onLoadMoreTapped = () => {
    this.props.loadMoreNotifications();
  };

  renderLoadMore() {
    const { hasNextPage, loadingMore } = this.props.notifications;
    if (!hasNextPage && !loadingMore) {
      return null;
    }

    if (loadingMore) {
      return <div className="loadingMore">Loading more...</div>;
    }

    const { tintColor } = this.props;
    const style = Object.assign(
      {},
      {
        ...(tintColor && { color: tintColor }),
      }
    );
    return (
      <Tappable onTap={this.onLoadMoreTapped}>
        <div className="loadMore" style={style}>
          Load more
        </div>
      </Tappable>
    );
  }

  renderList() {
    const { items } = this.props.notifications;
    if (!items.length) {
      return (
        <div className="nullState">
          <span>You have no notifications.</span>
        </div>
      );
    }

    const notifications = [];
    items.forEach((notification) => {
      notifications.push(
        <Notification key={notification._id} notification={notification} tint={true} />
      );
    });

    return (
      <div className="notificationsList">
        <div className="notifications">{notifications}</div>
        {this.renderLoadMore()}
      </div>
    );
  }

  renderContents() {
    const { viewer } = this.props;
    if (!viewer || !viewer._id) {
      return <div className="loggedOut">You are not logged in.</div>;
    }

    const { notifications } = this.props;
    if (!notifications || notifications.loading) {
      return (
        <div className="loading">
          <Spinner />
        </div>
      );
    }

    return this.renderList();
  }

  render() {
    return (
      <div className="widgetNotifications">
        <div className="header">
          <div className="title">Notifications</div>
          <WidgetHomeLink />
        </div>
        {this.renderContents()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    loadMoreNotifications: () => {
      return dispatch(loadMoreNotifications());
    },
  })),
  withContexts(
    {
      tintColor: TintColorContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(WidgetNotifications);
