import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import PostStatusTypes from 'common/postStatus/PostStatusTypes';
import getCannyOrigin from 'common/util/getCannyOrigin';
import withContexts from 'common/util/withContexts';

import WidgetBoardDetails from './WidgetBoardDetails';
import WidgetHomeLink from './WidgetHomeLink';
import CannyAttribution from '../CannyAttribution';
import Button from '../inputs/Button';
import Months from '../Months';
import PostStatus from '../post/PostStatus';
import SubdomainPostVoters from '../subdomain/public/SubdomainPostVoters';
import UppercaseHeader from '../UppercaseHeader';

import 'css/components/widget/_WidgetSidebar.scss';

class WidgetSidebar extends Component {
  static propTypes = {
    board: PropTypes.object,
    company: PropTypes.object,
    post: PropTypes.object,
  };

  renderCategory() {
    const { board, post } = this.props;
    if (!post || !board || !board.categories || board.categories.length === 0) {
      return null;
    }

    const label = post.category ? post.category.name : 'Uncategorized';
    return (
      <div className="category">
        <UppercaseHeader>Category</UppercaseHeader>
        <div className="label">{label}</div>
      </div>
    );
  }

  renderEntrySection() {
    const { company, post } = this.props;
    if (!post || !post.linkedEntry) {
      return null;
    }

    const entryURL = getCannyOrigin(company) + '/changelog/' + post.linkedEntry.urlName;
    const status = company.statuses.find((status) => status.name === post.status);
    const isComplete = status.type === PostStatusTypes.Complete;
    return (
      <div className="entrySection">
        {isComplete ? <PostStatus status={status.name} /> : null}
        <div className="message">Go to our changelog to read the official release notes.</div>
        <a href={entryURL} rel="noopener" target="_blank">
          <Button buttonType="blackButton" value="Read more" />
        </a>
      </div>
    );
  }

  renderPostETA() {
    const { post } = this.props;
    if (!post || !post.eta || !post.etaPublic) {
      return null;
    }

    const eta = new Date(post.eta);
    const month = Months[eta.getUTCMonth()];
    const year = eta.getUTCFullYear();

    return (
      <div className="eta">
        <UppercaseHeader>Estimated</UppercaseHeader>
        <div className="label">{month + ' ' + year}</div>
      </div>
    );
  }

  renderVoters() {
    const { board, post } = this.props;
    return (
      <div className="voters">
        <UppercaseHeader>Voters</UppercaseHeader>
        <SubdomainPostVoters board={board} post={post} voters={post.voters} />
      </div>
    );
  }

  renderSidebar() {
    const { board, post } = this.props;
    if (post) {
      return (
        <div className="sidebar post">
          <WidgetHomeLink />
          {this.renderEntrySection()}
          {this.renderPostETA()}
          <div className="sidebarPostInfoContainer">
            {this.renderCategory()}
            {this.renderVoters()}
          </div>
          <CannyAttribution desktopOnly={true} externalLink={true} source="feedback_widget" />
        </div>
      );
    } else {
      return (
        <div className="sidebar board">
          <WidgetBoardDetails board={board} />
          <CannyAttribution desktopOnly={true} externalLink={true} source="feedback_widget" />
        </div>
      );
    }
  }

  render() {
    const { board, post } = this.props;
    if (board.error) {
      return this.props.children;
    }

    if (post && (post.error || post.notFound)) {
      return this.props.children;
    }

    if (!post) {
      return (
        <div className="widgetSidebar">
          <WidgetBoardDetails board={board} />
        </div>
      );
    }

    return (
      <div className="widgetSidebar">
        {this.renderSidebar()}
        <div className="mainContainer">{this.props.children}</div>
      </div>
    );
  }
}

export default withContexts({ company: CompanyContext })(WidgetSidebar);
